import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import App from './App';
import Login from "./components/Login";
import Register from "./components/Register";
import Profile from "./components/Profile";
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

import common_en from "./translations/en/common.json";
import common_fr from "./translations/fr/common.json";
import EmailConfirmation from "./components/EmailConfirmation";
import ResetPassword from "./components/ResetPassword";
import ResetPasswordNewPassword from "./components/ResetPasswordNewPassword";
import LoggedLayout from "./components/LoggedLayout";
import Dashboard from "./components/Dashboard";
import ProfileStripe from "./components/ProfileStripe";
import LocationIndex from "./components/LocationIndex";
import LocationInfo from "./components/LocationInfo";
import LocationModal from "./components/LocationModal";
import LocationGeocode from "./components/LocationGeo";
import LocationServices from "./components/LocationServices";
import LocationSchedules from "./components/LocationSchedules";
import VehicleIndex from "./components/VehicleIndex";
import VehicleModal from "./components/VehicleModal";
import VehicleStep1 from "./components/VehicleStep1";
import VehicleStep2 from "./components/VehicleStep2";
import TestStripe from "./components/TestStripe";
import ServiceModal from "./components/ServiceModal";

import './App.css';
import {AccountType} from "./constants/AccountType";
import LocationGroupRedirect from "./features/branded/LocationGroupRedirect";
import store from "./store/store";
import {Provider} from "react-redux";
import BrandedExit from "./features/branded/BrandedExit";
import BrandedContainer from "./features/branded/BrandedContainer";
import StripeSubscription from "./components/StripeSubscription";
import ShowcasePannel from "./features/showcase/ShowcasePannel";

let language = localStorage.getItem('language');
if (language == null) {
    language = 'fr';
}

i18next.init({
    interpolation: {escapeValue: false},  // React already does escaping
    lng: language,                              // language to use
    resources: {
        en: {
            common: common_en               // 'common' is our custom namespace
        },
        fr: {
            common: common_fr
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <I18nextProvider i18n={i18next}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<App/>}>
                        {/* LocationGroup */}
                        <Route path="lg/:lgSlug" element={<LocationGroupRedirect/>}/>
                        <Route path="lg/:lgSlug/email_validation" element={<LocationGroupRedirect/>}/>

                        <Route path="lgs" element={<BrandedExit/>}/>

                        <Route path="login" element={<Login/>}/>
                        <Route path="register" element={<Register/>}/>
                        <Route path="email_validation" element={<EmailConfirmation/>}/>
                        <Route path="reset_password" element={<ResetPassword/>}/>
                        <Route path="reset_password_new_password" element={<ResetPasswordNewPassword/>}/>

                        <Route path="dashboard"
                               element={<LoggedLayout allowedFor={AccountType.PROVIDER} children={<Dashboard/>}/>}>
                            <Route path=""
                                   element={<LoggedLayout allowedFor={AccountType.PROVIDER} children={<Dashboard/>}/>}/>
                            <Route path=":locationId"
                                   element={<LoggedLayout allowedFor={AccountType.PROVIDER} children={<Dashboard/>}/>}/>
                            <Route path=":locationId/:tabIndex"
                                   element={<LoggedLayout allowedFor={AccountType.PROVIDER} children={<Dashboard/>}/>}/>
                        </Route>
                        <Route path="profile" element={<LoggedLayout children={<Profile/>} section="Profile"/>}/>
                        <Route path="profile_stripe"
                               element={<LoggedLayout allowedFor={AccountType.PROVIDER} children={<ProfileStripe/>}/>}/>
                        <Route path="stripe_callback"
                               element={<LoggedLayout allowedFor={AccountType.PROVIDER} children={<ProfileStripe/>}/>}/>
                        <Route path="location/"
                               element={<LoggedLayout allowedFor={AccountType.PROVIDER} children={<LocationIndex/>}/>}>
                            <Route path="add"
                                   element={<LocationModal step="0" children={<LocationGeocode/>}/>}/>
                            <Route path="edit/:locationId"
                                   element={<LocationModal step="0" children={<LocationGeocode/>}/>}/>
                            <Route path="info/:locationId"
                                   element={<LocationModal step="1" children={<LocationInfo/>}/>}/>
                            <Route path="services/:locationId"
                                   element={<LocationModal step="2" children={<LocationServices/>}/>}/>
                            <Route path="schedules/:locationId"
                                   element={<LocationModal step="3" children={<LocationSchedules/>}/>}/>
                        </Route>
                        <Route path="showcase/">
                            <Route path=""
                                   element={<LoggedLayout allowedFor={AccountType.PROVIDER}
                                                          children={<ShowcasePannel />}/>}/>
                            <Route path="payment_setup_callback"
                                   element={<LoggedLayout allowedFor={AccountType.PROVIDER}
                                                          children={<ShowcasePannel step={3}
                                                                                    isCreationCallback={true}/>}/>}/>
                            <Route path="payment"
                                   element={<LoggedLayout allowedFor={AccountType.PROVIDER}
                                                          children={<ShowcasePannel step={3}
                                                                                    isCreationCallback={false}/>}/>}/>

                            {/* TODO Others ? */}
                            {/*<Route path="header"*/}
                            {/*       element={<LoggedLayout allowedFor={AccountType.PROVIDER}*/}
                            {/*                              children={<ShowcasePannel step={1}/>}/>}/>*/}
                        </Route>

                        <Route path="vehicle/">
                            <Route path=""
                                   element={<LoggedLayout allowedFor={AccountType.CUSTOMER}
                                                          children={<VehicleIndex/>}
                                                          section="VehicleIndex"
                                   />}>
                                <Route path="add"
                                       element={<VehicleModal step="0" children={<VehicleStep1/>}/>}/>
                                <Route path="step1/:vehicleId"
                                       element={<VehicleModal step="0" children={<VehicleStep1/>}/>}/>
                                {/* NB : util uniquement pour bien identifier qu'on est tjrs en création */}
                                <Route path="add_step2/:vehicleId"
                                       element={<VehicleModal step="1" children={<VehicleStep2/>}/>}/>
                                <Route path="step2/:vehicleId"
                                       element={<VehicleModal step="1" children={<VehicleStep2/>}/>}/>
                            </Route>
                            <Route path=":vehicleId"
                                   element={<LoggedLayout allowedFor={AccountType.CUSTOMER}
                                                          children={<VehicleIndex/>}
                                                          section="VehicleIndex"
                                   />}/>
                        </Route>
                        <Route path="service_order"
                               element={<LoggedLayout allowedFor={AccountType.CUSTOMER} children={<VehicleIndex/>}
                                                      section="VehicleIndex"/>}>
                            <Route path=""
                                   element={<ServiceModal/>}/>
                        </Route>


                        <Route path="service_order_b"
                               element={<BrandedContainer allowedFor={AccountType.CUSTOMER}
                                                          children={<ServiceModal/>}/>}>
                        </Route>

                        <Route path="stripe_payment_callback"
                               element={<LoggedLayout allowedFor={AccountType.CUSTOMER} children={<VehicleIndex/>}
                                                      section="VehicleIndex"/>}>
                            <Route path=""
                                   element={<ServiceModal/>}/>
                        </Route>

                        <Route path="test_stripe_abo" element={<LoggedLayout children={<StripeSubscription/>}/>}/>
                        <Route path="test_stripe_abo_callback"
                               element={<LoggedLayout children={<StripeSubscription/>}/>}/>

                        <Route path="test_stripe" element={<LoggedLayout children={<TestStripe/>}/>}/>
                        <Route path="test_stripe_callback"
                               element={<LoggedLayout children={<TestStripe/>}/>}/>
                        <Route path="*" element={<Login/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        </I18nextProvider>
    </Provider>
// </React.StrictMode>
)
;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals