import React, {forwardRef, useContext, useEffect, useRef, useState} from "react";
import {
    Box,
    Container, FormControlLabel, FormGroup,
    Grid,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";
import {useOutletContext, useParams} from "react-router-dom";
import ServicePriceItem from "./ServicePriceItem";
import ServiceOptionPriceItem from "./ServiceOptionPriceItem";
import FormatUtils from "../utils/FormatUtils";
import '../App.css';
import withReactContent from "sweetalert2-react-content";
import moment from "moment";
import useFetch from "../hooks/useFetch";
import useAlert from "../hooks/useAlert";
import MobileContext from "../contexts/mobileContext";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {ServiceCarousel} from "./ServiceCarousel";
import ValetHelper from "../helpers/ValetHelper";
import {LocationValetStatus} from "../constants/LocationValetStatus";
import {KucPhoneTextField} from "./common/KucPhoneTextField";

function ServiceStep3({
                          previousBtn,
                          nextBtn,
                          contentLoadedHandler,
                          setLabel,
                          vehicle,
                          location,
                          tires,
                          validateService,
                          service,
                          serviceOptions,
                          day,
                          period,
                          userData,
                          valetSearchHandler,
                          valetData,
                          comment,
                          phone,
                          retrieveHour,
                          valetComment
                      }, ref) {
    const [apiUri] = useOutletContext();
    const {t, i18n} = useTranslation('common');
    const {callPost} = useFetch();
    const {showWarning} = useAlert();
    const MySwal = withReactContent(Swal);

    const [loaded, setLoaded] = useState(false);

    const [serviceData, setServiceData] = useState();
    const [servicesData, setServicesData] = useState();
    const [storeTiresData, setStoreTiresData] = useState();

    const [localSelectedService, setLocalSelectedService] = useState(service);
    const [localSelectedOptions, setLocalSelectedOptions] = useState(serviceOptions);
    const [localRetrieveHour, setLocalRetrieveHour] = useState(retrieveHour);
    const [localComment, setLocalComment] = useState(comment);

    const [localPhone, setLocalPhone] = useState('');
    const [validPhone, setValidPhone] = useState();

    const selectedServiceRef = useRef(null);
    const selectedOptionsRef = useRef(null);
    const retrieveHourRef = useRef(null);
    const commentRef = useRef(null);
    const phoneRef = useRef(null);
    const validPhoneRef = useRef(null);

    const storeTireOptionRef = useRef(null);
    const valetOptionRef = useRef(null);
    const valetOptionComponentRef = useRef();   // Reference to the compoent, to gather valet_comment

    const [options, setOptions] = useState([]);

    const [canValet, setCanValet] = useState(null);
    const [forcedValet, setForcedValet] = useState(null);

    const valetDataRef = useRef(null);
    valetDataRef.current = valetData;

    selectedServiceRef.current = localSelectedService;
    selectedOptionsRef.current = localSelectedOptions;
    retrieveHourRef.current = localRetrieveHour;
    commentRef.current = localComment;
    phoneRef.current = localPhone;
    validPhoneRef.current = validPhone;

    const storeTireOption = {};
    storeTireOption.isStoreTire = true;
    storeTireOption.name = t('service.step3.tire_storage_label');
    storeTireOption.description = t('service.step3.tire_storage_description');
    storeTireOption.price = {};
    storeTireOption.price.price = storeTiresData;

    const valetOption = {};
    valetOption.type = 'valet';
    valetOption.name = t('service.step3.valet_label');
    valetOption.description = t('service.step3.valet_description_period');

    const scrollToDown = useRef(null);

    const mobileContext = useContext(MobileContext);
    const isMobile = mobileContext.isMobile;

    useEffect(() => {
        setLabel('service.step3.title');
        previousBtn.current.style.display = '';
        nextBtn.current.style.display = '';
        nextBtn.current.addEventListener('click', nextClickedHandler);

        servicesForVehicleGet();

        if (retrieveHour == null) {
            setDefaultRetrieveHour();
        }

        if (phone != null) {
            setLocalPhone(phone);
        } else {
            setLocalPhone(userData.phone);
        }

        return () => {
            if (nextBtn.current) {
                nextBtn.current.removeEventListener('click', nextClickedHandler);
            }
        }

    }, [])

    useEffect(() => {
        if (localSelectedService == null) {
            return;
        }
        // console.log("storeTiresData", storeTiresData)
        if (storeTiresData) {
            storeTireOptionRef.current = storeTireOption;
        } else {
            storeTireOptionRef.current = null;
        }

        setOptions(getOptions());
    }, [storeTiresData, localSelectedService]);

    useEffect(() => {
        if (localSelectedService == null) {
            return;
        }
        if (canValet === true) {
            if (!forcedValet && tooLateForValet()) {
                valetOption.enabled = false;
                valetOption.description = t('service.step3.valet_description_disabled');
            } else {
                valetOption.enabled = true;
                let startEnd = FormatUtils.getPeriodStartEnd(day, period);
                valetOption.description =
                    FormatUtils.changeTextWithValues(
                        t('service.step3.valet_description_period'), {
                            "hour_min": startEnd[0],
                            "hour_max": startEnd[1]
                        });
            }
            valetOption.forced = forcedValet;
            valetOptionRef.current = valetOption;
        } else {
            valetOptionRef.current = null;
        }
        setOptions(getOptions());

        if (valetOption.forced) {
            let currentValetOptions = localSelectedOptions.filter(option => option.type == 'valet');
            // On l'ajoute si pas encore présente ( cas ou on revient en arrière, faut pas l'ajouter 2 fois !)
            if (currentValetOptions.length == 0) {
                localSelectedOptions.push(valetOption);
            }
        }

    }, [canValet, localSelectedService]);

    useEffect(() => {
        if (localSelectedService != null && scrollToDown.current != null && !isMobile) {
            scrollToDown.current.scrollIntoView({behavior: "instant"});
        }
    }, [localSelectedService, loaded]);

    // useEffect(() => {
    //     console.log("VALID PHONE=", validPhone);
    // }, [validPhone]);

    function getOptions() {

        let options = [...localSelectedService.options];
        if (storeTireOptionRef.current) {
            options.unshift(storeTireOptionRef.current);
        }
        if (valetOptionRef.current) {
            options.unshift(valetOptionRef.current);
        }
        // console.log("getOptions()" + valetOptionRef.current);
        return options;
    }

    function serviceSelectedHandler(serviceData) {
        // console.log('serviceSelectedHandler serviceData:', serviceData);
        setLocalSelectedService(serviceData);
    }

    function optionSelectedHandler(optionData, selected) {
        if (optionData.forced) {
            showWarning(t('service.step3.warning_option_forced'));
            return;
        }

        if (selected) {
            if (localSelectedOptions.indexOf(optionData) == -1) {
                localSelectedOptions.push(optionData);
            }
        } else {
            localSelectedOptions.splice(localSelectedOptions.indexOf(optionData), 1);
        }
        // console.log(localSelectedOptions);
        setLocalSelectedOptions(localSelectedOptions.slice(0));
    }

    // TODO Meme que VehicleIndex
    async function servicesForVehicleGet() {

        let data = {
            'vehicle_id': vehicle.id,
            'location_id': location.id,
            'tires': tires,
            'day': day.date,
            'period': period
        }

        callPost(apiUri + 'service_for_vehicle', data, (resultData => {
            contentLoadedHandler();
            setServiceData(resultData);
            setServicesData(resultData.services);
            if ('store_tires_price' in resultData) {
                setStoreTiresData(resultData.store_tires_price);
            } else {
                setStoreTiresData(null);
            }
            setCanValet([LocationValetStatus.ENABLED, LocationValetStatus.ENABLED_FORCED].includes(resultData.can_valet));
            setForcedValet(resultData.can_valet == LocationValetStatus.ENABLED_FORCED);
            setLoaded(true);
        }));
    }

    function tooLateForValet() {
        return serviceData.too_late_valet;
    }

    function getMinMaxTimes() {
        //let min = period == 'am' ? day.am_start : day.pm_start;
        //let max = period == 'am' ? day.am_end : day.pm_end;

        // Le min et max dépend des horaires du jour, pas de la période selectionnée
        let min = day.am_closed == 0 ? day.am_start : day.pm_start;
        let max = day.pm_closed == 0 ? day.pm_end : day.am_end;

        let minTime = moment(min, [moment.ISO_8601, 'HH:mm']);
        let maxTime = moment(max, [moment.ISO_8601, 'HH:mm']);

        return [minTime, maxTime];
    }

    function setDefaultRetrieveHour() {
        let times = getMinMaxTimes();
        let maxTime = times[1];
        setLocalRetrieveHour(maxTime.format("HH:mm"));
    }

    function isOptionSelected(optionData) {
        // Cas standard
        if (localSelectedOptions.indexOf(optionData) != -1) {
            return true;
        }

        // Cas du gardiennage
        if (optionData.isStoreTire) {
            let result = localSelectedOptions.some(oData => {
                if (oData.isStoreTire) {
                    return true;
                }
            });
            return result;
        }

        // Cas du valet
        if (optionData.type == 'valet') {
            let result = localSelectedOptions.some(oData => {
                if (oData.type == 'valet') {
                    return true;
                }
            });
            return result;
        }

        return false;
    }

    function nextClickedHandler() {
        // A selectionné un service ?
        if (selectedServiceRef.current == null) {
            showWarning(t('service.step3.warning_choose_service'));
            return;
        }

        // A rempli un téléphone ?
        if (phoneRef.current == null) {
            showWarning(t('service.step3.warning_phone'));
            return;
        }

        // Téléphone OK ?
        if (!validPhoneRef.current) {
            showWarning(t('service.step3.warning_phone_valid'));
            return;
        }


        let times = getMinMaxTimes();
        let minTime = times[0];

        let maxTime = moment("23h45", [moment.ISO_8601, 'HH:mm']);
        minTime.add(1, 'hours');

        if (retrieveHourRef.current == "") {
            showAlertForRetrieveDate(minTime, maxTime);
            return;
        }

        let inputTime = moment(retrieveHourRef.current, [moment.ISO_8601, 'HH:mm']);

        if (!inputTime.isBetween(minTime, maxTime, undefined, '[]')) {
            showAlertForRetrieveDate(minTime, maxTime);
            return;
        }

        // Valet
        let valetOption = selectedOptionsRef.current.filter((option) => option.type == 'valet');
        // Validé ?
        if (valetOption.length > 0 && (valetDataRef.current == null || !ValetHelper.isValetDataOk(valetDataRef.current))) {
            showWarning(t('service.step3.warning_valet_not_validated'));
            return;
        }

        // Confirmation de l'addresse
        if (valetOption.length > 0 && valetDataRef.current != null) {
            MySwal.fire({
                html: t('service.step3.confirmation_address') + '<br/><b>' + valetDataRef.current.gmap_origin + '</b>',
                showCancelButton: true,
                confirmButtonText: t('service.step3.confirmation_address_validate'),
                cancelButtonText: t('common.cancel'),
                target: document.getElementById('swal_container')
            }).then((result) => {
                if (result.isConfirmed) {
                    callValidateService();
                } else if (result.isDenied) {
                    return;
                }
            })
            return;
        }

        callValidateService();
    }

    function callValidateService() {
        validateService(selectedServiceRef.current,
            selectedOptionsRef.current,
            retrieveHourRef.current,
            commentRef.current,
            phoneRef.current,
            valetOptionComponentRef.current?.getValetComment() ?? null
        );
    }

    function showAlertForRetrieveDate(minTime, maxTime) {
        MySwal.fire({
            text: FormatUtils.changeTextWithValues(
                t('service.step3.retrieve_hour_warning'),
                {
                    "min": minTime.format("HH:mm"),
                    "max": maxTime.format("HH:mm")
                }
            ),
            icon: 'warning',
            target: document.getElementById('swal_container')
        });
    }

    function selectedService(serviceData) {
        if (localSelectedService == null || serviceData == null) {
            return false;
        }
        return localSelectedService.id == serviceData.id;
    }

    return (
        <Container maxWidth={false}>
            {loaded &&
                <>
                    <Typography
                        fontSize={isMobile ? null : 20}
                        color="#fbc70f" m={2}
                        ml={isMobile ? 0 : 17}>
                        {t('service.step3.schedule_period_label') + FormatUtils.getPeriodHuman(day, period, i18n.language)}
                    </Typography>


                    {!isMobile &&
                        <Grid mt={2}
                              container
                              width={isMobile ? '100%' : null}
                              justifyContent={isMobile ? null : "space-evenly"}
                              alignItems={isMobile ? null : "center"}>
                            {servicesData.length == 0 ?
                                <Typography><b>{t('service.step3.no_service')}</b></Typography>
                                : (
                                    !isMobile ?
                                        servicesData.map((serviceData, i) => {
                                            return (<ServicePriceItem key={i} selected={selectedService(serviceData)}
                                                                      serviceData={serviceData}
                                                                      clickedHandler={serviceSelectedHandler}/>);
                                        })
                                        : <ServiceCarousel
                                            servicesData={servicesData}
                                            selectedService={localSelectedService}
                                            serviceSelectedHandler={serviceSelectedHandler}
                                        />

                                )
                            }
                        </Grid>
                    }

                    {isMobile && (
                        servicesData.length == 0 ?
                            <Typography><b>{t('service.step3.no_service')}</b></Typography>
                            : (
                                <ServiceCarousel
                                    servicesData={servicesData}
                                    selectedService={localSelectedService}
                                    serviceSelectedHandler={serviceSelectedHandler}
                                />
                            ))
                    }

                    {localSelectedService &&
                        <Grid
                            marginTop={isMobile ? '10px' : null}
                        >
                            <Typography display="inline" mt={1}
                                        fontSize={isMobile ? 15 : 20}>{t('service.step3.options_label')} - </Typography>
                            <Typography display="inline" mb={2}
                                        fontSize={isMobile ? '0.8em' : null}>{t('service.step3.options_subtitle')}</Typography>
                            <Stack spacing={2}>
                                {
                                    options.length == 0 ?
                                        <Typography><b>{t('service.step3.no_option')}</b></Typography>
                                        :
                                        options.map((optionData, i) => {
                                            // console.log(selectedOptions.indexOf(optionData) != -1)
                                            return (<ServiceOptionPriceItem key={i}
                                                                            selected={isOptionSelected(optionData)}
                                                                            optionData={optionData}
                                                                            serviceData={localSelectedService}
                                                                            locationData={location}
                                                                            clickedHandler={optionSelectedHandler}
                                                                            valetSearchHandler={valetSearchHandler}
                                                                            valetData={valetData}
                                                                            valetComment={valetComment}
                                                                            ref={(optionData.type == 'valet') ? valetOptionComponentRef : null}
                                            />);
                                        })
                                }
                            </Stack>
                        </Grid>
                    }

                    <Grid container mt={isMobile ? 1 : null}>
                        <FormGroup>
                            <FormControlLabel
                                labelPlacement="start" control={<TextField
                                type="time"
                                style={{
                                    width: isMobile ? 200 : 150,
                                    paddingLeft: isMobile ? null : '20px',
                                }}
                                sx={{
                                    '& input[type="time"]::-webkit-calendar-picker-indicator': {
                                        filter: 'invert(100%)'
                                    },
                                }}
                                size="small"
                                inputProps={{style: {textAlign: 'center'}}}
                                value={localRetrieveHour}
                                onChange={(e) => {
                                    setLocalRetrieveHour(e.currentTarget.value);
                                }}
                            />} label={<Typography fontSize={isMobile ? null : 20}
                                                   color="#fbc70f"
                                                   m={isMobile ? 0 : 2}
                                                   ml={isMobile ? '-16px' : 15}
                                                   mr={isMobile ? '5px' : null}
                            >
                                {t('service.step3.retrieve_hour_label')}
                            </Typography>}/>
                        </FormGroup>
                        <Box width="100%"/>
                        <FormGroup
                            sx={{
                                width: isMobile ? '100%' : null,
                            }}
                            style={{
                                // backgroundColor: '#afbff0'
                            }}
                        >
                            <FormControlLabel
                                style={{
                                    marginLeft: isMobile ? 0 : null,
                                    marginRight: isMobile ? 0 : null,
                                    alignItems: isMobile ? 'flex-start' : null
                                }}
                                labelPlacement={isMobile ? 'top' : 'start'}
                                control={
                                    <TextField
                                        style={{
                                            width: isMobile ? '100%' : '565px',
                                            paddingLeft: isMobile ? null : '20px',
                                        }}
                                        multiline
                                        rows={3}
                                        placeholder={t('service.step3.comment_placeholder')}
                                        value={localComment}
                                        onChange={(e) => {
                                            setLocalComment(e.currentTarget.value);
                                        }}
                                    />
                                }
                                label={<Typography fontSize={isMobile ? 18 : 20}
                                                   color="#fbc70f"
                                                   m={isMobile ? null : 2}
                                                   mt={isMobile ? 1 : null}
                                                   ml={isMobile ? 0 : 15}>
                                    {t('service.step3.comment_label')}
                                </Typography>}/>
                        </FormGroup>
                        <Box width="100%"/>
                        <FormGroup>
                            <FormControlLabel
                                labelPlacement={isMobile ? 'top' : 'start'} control={
                                <KucPhoneTextField
                                    value={localPhone}
                                    onChange={(value, isValid) => {
                                        setLocalPhone(value);
                                        setValidPhone(isValid);
                                    }}
                                />

                            } label={<Typography fontSize={isMobile ? null : 20}
                                                 color="#fbc70f"
                                                 m={isMobile ? 0 : 2}
                                                 ml={isMobile ? '-16px' : 15}
                                                 mr={isMobile ? '5px' : null}
                            >
                                {t('service.step3.phone_label')}
                            </Typography>}
                            />

                        </FormGroup>
                        <Typography
                            ml={isMobile ? '' : 15}
                            style={{
                                paddingLeft: isMobile ? null : '20px',
                            }}>{t('service.step3.phone_hint')}</Typography>
                    </Grid>
                    <div ref={scrollToDown}/>
                </>
            }
        </Container>
    )
};

export default forwardRef(ServiceStep3);