import React, {useContext} from "react";
import {
    Box, Button,
    Container, Grid,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import logo from '../assets/logo-header.png';
import MobileContext from "../contexts/mobileContext";
import {useSelector} from "react-redux";
import {getIsBranded} from "../features/branded/locationGroupSlice";
import {Link} from "react-router-dom";

export default function ServiceSuccess({}) {

    const {t} = useTranslation('common');

    const mobileContext = useContext(MobileContext);
    const isMobile = mobileContext.isMobile;

    const isBranded = useSelector(getIsBranded);

    return (
        <Container>
            <Grid container justifyContent="center">
                {!isBranded &&
                <img style={{
                    width: isMobile ? '70%' : '40%',
                    marginTop: '50px'
                }} src={logo}/>
                }
                <Box width="100%" height="50px"/>
                <Typography fontWeight="bold" fontSize={isMobile ? '20px' : '25px'}
                            color="#fbc70f">{t('service.step_success.title')}</Typography>
                <Box width="100%" height="50px"/>
                <Typography fontWeight="bold" fontSize={isMobile ? '20px' : '25px'}
                            color="#fbc70f">{t('service.step_success.description')}</Typography>
                {isBranded &&
                <>
                    <Box width="100%" height="50px"/>
                    <Grid justifyContent="center">
                        <Link to="/lgs">
                            <Button
                                variant="contained"
                                color="yellow"
                                sx={{mt: '15px'}}
                            >
                                {t('service.step_success.back_to_kuc')}
                            </Button>
                        </Link>
                    </Grid>
                </>
                }
            </Grid>
        </Container>
    )
};