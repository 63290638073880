import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {Container, Grid, Modal, Stack, TextField, Typography} from "@mui/material";
import useFetch from "../hooks/useFetch";
import {useLocation, useOutletContext} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";
import {AccountType} from "../constants/AccountType";
import {useContext} from "react";
import MobileContext from "../contexts/mobileContext";
import ConfigurationContext from "../contexts/configurationContext";

export default function ContactForm() {

    const [open, setOpen] = React.useState(false);
    const {callPostForm} = useFetch();
    const [apiUri] = useOutletContext();
    const {t} = useTranslation('common');
    const formRef = React.useRef();
    const accountType = localStorage.getItem('accountType');
    const mobileContext = useContext(MobileContext);
    const isMobile = mobileContext.isMobile;

    const location = useLocation();

    const show = location.pathname != '/service_order' && location.pathname != '/service_order_b';

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: !isMobile ? 600 : '',
        width: isMobile ? '100%' : '50%',
        bgcolor: '#555555',
        border: '2px solid #000',
        borderRadius: '10px',
        // boxShadow: 24,
        p: 4,
    };

    const applicationSettingsContext = useContext(ConfigurationContext);

    function submitDataHandler() {
        if (!formRef.current.reportValidity()) {
            return;
        }

        let data = new FormData();
        data.append('message', formRef.current.elements['message'].value);
        data.append('phone', formRef.current.elements['phone'].value);

        callPostForm(apiUri + 'contact', data, (resultData) => {
            Swal.fire({
                text: t('contact.success'),
                icon: 'success',
                timer: 2000,
                target: document.getElementById('swal_container'),
            })
                .then(() => {
                        setOpen(false);
                    }
                );
        });
    }

    if (!show) {
        return null;
    }

    return (
        <>
            <div id="contact_form"
                 style={{
                     backgroundColor: '#000000',
                     position: "fixed",
                     display: "flex",
                     justifyContent: "center",
                     right: 0,
                     width: isMobile ? '100%' : "400px",
                     zIndex: "1500",
                     bottom: isMobile ? '0' : "50%",
                     transform: isMobile ? '' : 'translate(175px) rotate(-90deg) '
                 }}
            >

                <Stack>
                    {isMobile &&
                    <Typography style={{
                        marginLeft: '6px'
                    }}>{t('contact.need_help_title')}</Typography>
                    }
                    <Grid
                        style={{
                            height: '50px'
                        }}
                    >
                        <Button variant="contained"
                                style={{
                                    margin: '5px'
                                }}
                                onClick={() => {
                                    setOpen(true)
                                }}>
                            {t('contact.contact_button')}
                        </Button>
                        {accountType == AccountType.CUSTOMER &&
                        <Button variant="contained"
                                style={{
                                    margin: '5px'
                                }}
                                href={applicationSettingsContext == null ? '' : applicationSettingsContext.URL_MANUAL}
                                target="_blank"
                        >
                            {t('contact.manual_button')}
                        </Button>
                        }
                    </Grid>
                </Stack>
            </div>

            <Modal
                open={open}
                onClose={() => {
                    setOpen(false)
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography>{t('contact.title')}</Typography>
                    <form ref={formRef}>
                        <TextField
                            sx={{
                                mt: 1,
                                mb: 1
                            }}
                            id="message"
                            multiline
                            fullWidth
                            minRows={5}
                            label={t('contact.message_label')}
                            required
                        />
                        <TextField
                            sx={{
                                // mt:1,
                                mb: 1
                            }}
                            id="phone"
                            fullWidth
                            label={t('contact.phone_label')}
                            required
                        />
                        <Grid justifyContent="center" display="flex">
                            <Button variant="contained" onClick={submitDataHandler}>
                                {t('contact.send_button')}
                            </Button>
                        </Grid>
                    </form>
                </Box>
            </Modal>
        </>
    );
}