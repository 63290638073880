import {useOutletContext} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {Button, Container, Grid, IconButton} from "@mui/material";

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import ServiceLocationCalendarDay from "./ServiceLocationCalendarDay";
import useFetch from "../hooks/useFetch";
import MobileContext from "../contexts/mobileContext";
import moment from "moment";
import FormatUtils from "../utils/FormatUtils";

function ServiceLocationCalendar({
                                     locationId,
                                     periodClicked,
                                     selectedDay,
                                     selectedPeriod,
                                     renderedHandler
                                 }) {
    const [apiUri] = useOutletContext();
    const [calendarData, setCalendarData] = useState(null);

    const [from, setFrom] = useState(null); // day at sql format
    const {call} = useFetch();

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const mobileContext = useContext(MobileContext);
    const isMobile = mobileContext.isMobile;

    useEffect(() => {
        const handleWindowResize = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    useEffect(() => {
        renderedHandler();
    });

    useEffect(() => {
        console.log('huhu', selectedDay);
        if (selectedDay == null) {
            getFromServer(locationId, moment().format('YYYY-MM-DD'), FormatUtils.getSqlDateWithDayDiff(moment().format('YYYY-MM-DD'), getNumberOfDays()));
        } else {
            // On intéroge là ou il y a ce jour
            const dateToday = new moment();
            const dateSelected = new moment(selectedDay.date);
            const diffTime = dateSelected.diff(dateToday);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            const diffDaysMod = diffDays % getNumberOfDays();
            const lastDayDiff = -diffDaysMod + getNumberOfDays();
            getFromServer(locationId, FormatUtils.getSqlDateWithDayDiff(selectedDay.date, -diffDaysMod),FormatUtils.getSqlDateWithDayDiff(selectedDay.date, lastDayDiff));
        }
    }, [locationId])

    function getNumberOfDays() {
        // console.log(screenWidth);
        return isMobile ? 5 : 7;
    }

    function previousWeekClickedHandler(e) {
        periodClicked(null, null);
        getFromServer(locationId, FormatUtils.getSqlDateWithDayDiff(from, -getNumberOfDays()), from);
    }

    function nextWeekClickedHandler(e) {
        periodClicked(null, null);
        getFromServer(locationId, FormatUtils.getSqlDateWithDayDiff(from, getNumberOfDays()), FormatUtils.getSqlDateWithDayDiff(from, getNumberOfDays() * 2));
    }

    async function getFromServer(locationId, from = null, to = null) {
        // console.log('getFromServer', from, to);
        let url = apiUri + 'location_calendar/' + locationId;
        if (from != null) {
            url += '/' + from;
            if (to != null) {
                url += '/' + to;
            }
        }

        url += '?withCurrentCapacity=1';

        call(url, (resultData) => {
            setFrom(resultData.calendar[0].date);
            setCalendarData(resultData.calendar);
        });
    }

    let isCurrentWeek = false;
    if (calendarData) {
        calendarData.forEach(calendarDayData => {
            if ('isToday' in calendarDayData && calendarDayData.isToday == '1') {
                isCurrentWeek = true;
            }
        });
    }

    return (<Container
        disableGutters={true}
        maxWidth={false}
        style={{
            // backgroundColor: '#FF0000'
        }}
    >
        {calendarData &&
        (

            <Grid container
                // disableGutters={isMobile}
                  style={{
                      // backgroundColor: '#00FF00'
                  }}>
                <Grid item xs={0.5} style={{
                    // backgroundColor: '#0000FF'
                }}>
                    {!isCurrentWeek &&
                    <IconButton size="small" color="yellow" onClick={previousWeekClickedHandler}>
                        <NavigateBeforeIcon sx={
                            {
                                color: 'orange',
                                fontSize: 30,
                            }}/>
                    </IconButton>
                    }
                </Grid>
                {calendarData.map((calendarDayData, index) => {
                    return <Grid item xs={11 / getNumberOfDays()} key={index}>
                        <ServiceLocationCalendarDay calendarDayData={calendarDayData}
                                                    selectedDay={selectedDay}
                                                    selectedPeriod={selectedPeriod}
                                                    periodClicked={periodClicked}/>
                    </Grid>
                })}
                <Grid item xs={0.5} style={{
                    // backgroundColor: '#0000FF'
                }}>
                    <IconButton
                        size="small"
                        color="yellow"
                        onClick={nextWeekClickedHandler}
                        sx={{
                            width: isMobile ? '20px' : null,
                            marginLeft: isMobile ? '-10px' : null,
                        }}
                    >
                        <NavigateNextIcon sx={
                            {
                                color: 'orange',
                                fontSize: 30,
                            }}/>
                    </IconButton>
                </Grid>
            </Grid>
        )
        }
    </Container>);
}

export default ServiceLocationCalendar;