import {Container, Grid, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {Link, Outlet, useNavigate} from "react-router-dom";
import BrandedKucFooter from "./BrandedKucFooter";
import BrandedHeaderFooter from "./BrandedHeaderFooter";
import {useSelector} from "react-redux";
import {getIsBranded} from "./locationGroupSlice";
import RedirectToEntryPoint from "../../components/RedirectToEntryPoint";
import {useGetLocationGroupBySlugQuery} from "./LocationGroupApi";
import BrandedPreviewBanner from "./BrandedPreviewBanner";
import {AccountType} from "../../constants/AccountType";
import BrandedExit from "./BrandedExit";
import BrandedDisabledContent from "./BrandedDisabledContent";

export default function BrandedContainer({children}) {

    const navigate = useNavigate();
    const isBranded = useSelector(getIsBranded);
    const slug = useSelector(state => state.locationGroup.locationGroupData?.slug);
    const {data, error, isLoading} = useGetLocationGroupBySlugQuery(slug);
    const accountType = localStorage.getItem('accountType');

    // useEffect(() => {
    //     console.log('data!', data);
    // }, [data]);

    function isPreview() {
        return accountType == AccountType.PROVIDER;
    }

    // NB : le meme que LocationGroupRedirect, mais c'est nécéssaire de rechecké à chaque fois..
    function canShow() {
        return isPreview() || data?.active == 1;
    }

    if (!isBranded) {
        return <RedirectToEntryPoint/>;
    }

    return isLoading ? <></> : <Grid
        container
        // disableGutters={true}
        style={{
            backgroundColor: '#292e32',
            // backgroundColor: 'red',
            display: 'flex',
            minHeight: '100vh',
            width: "100%",
            // alignItems: 'center',

            flexDirection: 'column',
            justifyContent: 'space-between'
        }}

    >
        {canShow() ? <>
                {isPreview() &&
                <BrandedPreviewBanner/>
                }
                <BrandedHeaderFooter
                    descriptionLogo={data?.location_group?.description_logo}
                    description={data?.location_group?.description}
                />
                <Container disableGutters maxWidth={false} style={{
                    // backgroundColor:'red'
                }}>
                    {/*{children}*/}
                    {React.cloneElement(children, {brandData: data})}
                </Container>
                <BrandedHeaderFooter
                    // brandLogo={data?.location_group?.logo_uri}
                    description={data?.location_group?.description_bottom}
                />
                <BrandedKucFooter/>
            </> :
            <BrandedDisabledContent/>
        }
    </Grid>
}