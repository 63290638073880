import {Button, Grid, Typography} from "@mui/material";

import React, {useContext, useEffect} from "react";
import MobileContext from "../contexts/mobileContext";

// selectedHover = si true, laisse l'image de rollover
function ImageButton({
                         height = '50px',
                         selected,
                         selectedHover,
                         onClickHandler,
                         srcOver,
                         src,
                         width,
                         selectWithBackground = true,
                         text = null,
                         textSize = null,
                         subIcon = null
                     }) {

    const [hover, setHover] = React.useState(false);
    const [isSelected, setIsSelected] = React.useState(false);

    const mobileContext = useContext(MobileContext);
    const isMobile = mobileContext.isMobile;

    useEffect(() => {
        setIsSelected(selected);
    }, [selected])

    let sx = {};
    if (isSelected) {
        if (selectWithBackground) {
            sx.backgroundColor = 'orange';
            sx['&:hover'] = {backgroundColor: 'orange'};
        }
    }

    sx.minWidth = width;

    // Mode "Onglet"
    if (text != null) {
        if (selected) {
            sx.borderTop = 'solid thin orange';
        } else {
            sx.borderTop = 'solid thin grey';
        }
    }

    return <Button
        sx={sx}
        onMouseEnter={() => setHover(isMobile ? false : true)}
        onMouseOut={() => setHover(false)}
        onClick={() => {
            if (onClickHandler != null) {
                onClickHandler();
            }
        }
        }
    >
        <Grid container spacing={1}>
            <Grid item>
                <img
                    src={(hover || selectedHover || (!selectWithBackground && selected)) ? (srcOver ? srcOver : src) : src}
                    style={{
                        height: height
                    }}
                />
                {subIcon}
            </Grid>
            {text &&
            <Grid item display="flex" alignItems="center">
                <Typography fontSize={textSize} color={selected ? 'orange' : 'white'}>{text.toUpperCase()}</Typography>
            </Grid>
            }
        </Grid>
    </Button>

}

export default ImageButton;